export const documenttypeColumns = [
  {
    title: '文件类型名称',
    dataIndex: 'typeName',
    key: 'typeName',
    slots: { customRender: 'typeName' },
    width: '50%'
  },
  {
    title: '文件类型编号',
    dataIndex: 'typeNumber',
    key: 'typeNumber',
    ellipsis: true,
    slots: { customRender: 'typeNumber' },
    width: '40%'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
  //   align: 'left',
  width: '10%'
  }
]
  